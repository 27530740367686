import React from 'react';
import { Link } from 'gatsby';

const Pager = ({ pageContext }) => {
  console.log(pageContext);
  const { previousPagePath, nextPagePath, humanPageNumber,numberOfPages } = pageContext;
  const prevList = [];
  const nextList = [];
  // for prevList
  if (humanPageNumber - 5 > 1) {
    for (var i = humanPageNumber - 6; i < humanPageNumber; i++) {
      prevList[i] = i;
      prevList[humanPageNumber - 6] = 1;
      prevList[humanPageNumber - 5] = '...'
    }
  } else {
    for (var i = humanPageNumber - 5; i < humanPageNumber; i++) {
      if (i > 0) {
        prevList.push(i);
      }
    }
  }
  // for nextList
  if (humanPageNumber + 5 < numberOfPages) {
    for (var i = humanPageNumber + 1; i < humanPageNumber + 6; i++) {
      nextList[i] = i;
      nextList[humanPageNumber + 6] = numberOfPages;
      nextList[humanPageNumber + 5] = '...'
    }
  } else {
    for (var i = humanPageNumber + 1; i < humanPageNumber + 5; i++) {
      if (i <= numberOfPages) {
        nextList.push(i);
      }
    }
  }
  return (
    <nav className="pagination-bar">
      <div className="pagination-block">
        {previousPagePath && (
          <Link className="pagination-link" to={previousPagePath}>
            前へ
          </Link>
        )}
      </div>
      {prevList.map((i) => (
        <div className="pagination-block">      
          {i === 1 && (
            <Link className="pagination-link" to='/'>
              {i}
            </Link>
          )}
          {i == '...' && (
            <span> ...</span>
          )}
          {i !== 1 && i !== '...' && (
            <Link className="pagination-link" to={`/${i}`}>
              {i}
            </Link>
          )}
        </div>
      ))} 
      {humanPageNumber === 1 && (
        <Link to='/' className="current-page">
          {humanPageNumber}
        </Link>
      )}
      {humanPageNumber !== 1 && (
        <Link  to={`/${humanPageNumber}`} className="current-page">
          {humanPageNumber}
        </Link>
      )}
      {nextList.map((i) => (
        <div className="pagination-block" >      
          {i == '...' && (
            <span> ...</span>
          )}
          {i !== 1 && i !== '...' && (
            <Link className="pagination-link" to={`/${i}`}>
              {i}
            </Link>
          )}
        </div>
      ))} 
      <div className="pagination-block">
        {nextPagePath && (
          <div>
            <Link className="pagination-link" to={nextPagePath}>
              次へ
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Pager;

