import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pager from "../components/pager"
import Logo from "../images/default_logo.png"

const BlogArchive = ({ data, pageContext, location }) => {
  return (
    <div>
      <Layout location={location} title="DXデイリーポスト">
        <SEO title="世界の日々の最新DX情報を日本語で" description="" />
        {data.allStrapiScrapingData.edges.map(document => (
          <div>
            <div key={document.node.id} className="dx-row">
              <div className="dx-column left">
                {document?.node?.not_rss_ur_l?.Logo?.publicURL ? (
                  <img
                    className="company-img"
                    src={`${document?.node?.not_rss_ur_l?.Logo?.publicURL}`}
                    alt={`${document.node.companyname}_logo`}
                  />
                ) : (
                  <>
                    {document?.node?.rss_ur_l?.Logo?.publicURL ? (
                      <img
                        className="company-img"
                        src={`${document?.node?.rss_ur_l?.Logo?.publicURL}`}
                        alt={`${document.node.companyname}_logo`}
                      />
                    ) : (
                      <img
                        className="company-img"
                        src={Logo}
                        alt="logo"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="dx-column middle">
                <div>
                  <p className="newdate mobile">
                    <b>
                      {document.node.releasedate} &nbsp;&nbsp;
                      {document.node.companyname}
                    </b>
                  </p>
                  <p>{document.node.newstitle}</p>
                  <p>
                    <a
                      href={`${document.node.link}`}
                      target="_blank"
                      className="mobile"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
              <div className="dx-column right">
                <p className="newdate">
                  <b>
                    {document.node.releasedate} &nbsp;&nbsp;
                    {document.node.companyname}
                  </b>
                </p>
                <br />
                <p>
                  <a href={`${document.node.link}`} target="_blank">
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <hr />
          </div>
        ))}
        <div className="">
          <Pager pageContext={pageContext} />
        </div>
      </Layout>
    </div>
  )
}

export default BlogArchive

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiScrapingData(
      skip: $skip
      limit: $limit
      sort: { fields: releasedate, order: DESC }
    ) {
      edges {
        node {
          companyname
          link
          releasedate
          newstitle
          id
          not_rss_ur_l {
            Logo {
              publicURL
            }
          }
          rss_ur_l {
            Logo {
              publicURL
            }
          }
        }
      }
    }
  }
`

